import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from "./services/auth.service";
import {tap} from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(tap({
        error: err => {

          if (err.status === 401 && !this.authService.isLoggedIn()) {
            return;
          }

          if ([401, 403].includes(err.status)) {
            this.authService.logoutUser();
          }
        }
      }))
  }
}
