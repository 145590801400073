import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, switchMap, take} from "rxjs";
import {Appointment} from "../interfaces/appointment";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {LocationService} from "./location.service";
import {StyleService} from "./style.service";
import {Store} from "@ngrx/store";
import {selectAppointment, selectAppointmentState} from "../members/appointment/store/appointment.selectors";
import {selectSurveyData} from "../members/survey/store/survey.selectors";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  /**
   * Current Appointment ID Selector Subject
   */
  public selectorId$: Observable<string | null> = this.store.select(selectAppointmentState).pipe(map(state => state.iri));

  /**
   * Current Appointment Subject
   */
  public appointment$: Observable<Appointment | null> = this.store.select(selectAppointment);

  constructor(
    private readonly http: HttpClient,
    private readonly locationService: LocationService,
    private readonly styleService: StyleService,
    private readonly store: Store
  ) {
    this.selectorId$
      .pipe(
        distinctUntilChanged(),
        tap(iri => !!iri ? localStorage.setItem('appointment', iri) : localStorage.removeItem('appointment')),
      )
      .subscribe();
  }

  getAppointment(appIri: string) {
    return this.http.get<Appointment>(appIri)
      .pipe(
        tap(a => this.styleService.setConfig(a.location.config)),
        tap(a => this.locationService.setLocationUrl(a.location.url))
      );
  }

  public uploadSurvey() {
    return this.appointment$
      .pipe(
        take(1),
        filter(a => !!a),
        map(a => a!),
        switchMap(appointment => this.store.select(selectSurveyData).pipe(
          switchMap(survey => {
              const text = survey.filter(i => i.text.length > 0).map(i => i.text).join('\n');

              return this.http.patch<Appointment>(
                appointment['@id'], {
                  event: {
                    remark: text,
                    surveyText: text
                  }
                },
                {
                  headers: new HttpHeaders({'Content-Type': 'application/merge-patch+json'})
                }
              )
            }
          )
        )))
  }
}
