import {Component} from '@angular/core';
import {LoadScreenDirective} from "../../directives/load-screen.directive";

@Component({
  selector: 'app-css-loader',
  standalone: true,
  imports: [LoadScreenDirective],
  template: `
    <div class="load-screen" appLoadScreen>
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  `,
  styleUrls: ['./css-loader.component.scss']
})
export class CssLoaderComponent {
}
