import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, filter, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as UserActions from './user.actions';
import {UserService} from "../../../services/user.service";
import * as SurveyActions from "../../survey/store/survey.actions";
import {Store} from "@ngrx/store";


@Injectable()
export class UserEffects {

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      switchMap(action => this.userService.getUser(action.iri).pipe(
        map(user => {

          const surveyData = Object.entries(user.checkIn?.survey ?? {})
            .map(([key, value]) => {
              return {key, raw: value, text: ''}
            })

          this.store.dispatch(SurveyActions.setSurveyUserData({data: surveyData}));

          return UserActions.loadUserSuccess({data: user});
        }),
        catchError(error => of(UserActions.loadUserFailure({error})))
      ))
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUser),
      concatMap(action => this.userService.updateUser(action.iri, action.data).pipe(
        map(user => UserActions.updateUserSuccess({data: user})),
        catchError(error => of(UserActions.updateUserFailure({error})))
      ))
    )
  })

  updateUserSurvey$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SurveyActions.setSurveyItem),
        filter(action => {
          return [
            'vorbehandlungen',
            'allergien',
            'vorerkrankungen',
            'medikamente'
          ].some(v => v === action.item.key)
        }),
        concatMap(action => this.userService.setSurvey(action.item).pipe(
          map(user => UserActions.updateUserSuccess({data: user})),
          catchError(error => of(UserActions.updateUserFailure({error})))
        ))
      )
    })

  constructor(
    private actions$: Actions,
    private readonly store: Store,
    private readonly userService: UserService
  ) {
  }
}
