import {createAction, props} from '@ngrx/store';
import {User} from "../../../interfaces/user";
import {HttpErrorResponse} from "@angular/common/http";

export const setUsersIri = createAction(
  '[User] Set User IRI',
  props<{ iri: string | null }>()
)

export const loadUser = createAction(
  '[User] Load User',
  props<{ iri: string }>()
);

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ data: User }>()
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: HttpErrorResponse }>()
);

export const updateUser = createAction(
  '[User] Update User',
  props<{ iri: string; data: any }>()
);

export const updateUserSuccess = createAction(
  '[User] Update User Success',
  props<{ data: User }>()
);

export const updateUserFailure = createAction(
  '[User] Update User Failure',
  props<{ error: HttpErrorResponse }>()
);

/**
 * Update Check-In Data
 */
export const updateCheckInData = createAction(
  '[User] Update User Check-In Data'
)

export const updateCheckInDataSuccess = createAction(
  '[User] Update User Check-In Data Success'
)

export const updateCheckInDataFailure = createAction(
  '[User] Update User Check-In Data Failure',
  props<{ error: HttpErrorResponse }>()
)

/**
 * Logout
 */

export const logoutUser = createAction(
  '[User] Logout'
)
