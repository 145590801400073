import {Injectable} from '@angular/core';
import {LocationConfig} from "../interfaces/location";

@Injectable({
  providedIn: 'root'
})
export class StyleService {
  public setConfig(config?: LocationConfig) {
    if (!config) {
      return;
    }

    this.setLogo(config.logo);

    if (config.cssList) {
      document.body.dataset['location'] = config.cssList;
    }
  }

  private setLogo(logo?: string | null) {
    if (!logo) {
      return;
    }
  }
}
