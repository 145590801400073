import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadScreenService {

  public status$ = new BehaviorSubject<boolean>(true);
  public status = this.status$.asObservable();
  
  public close() {
    this.status$.next(false);
  }

  public show() {
    this.status$.next(true);
  }
}
