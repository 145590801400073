import {Action, createReducer, on} from '@ngrx/store';
import * as AppointmentActions from './appointment.actions';
import {Appointment} from "../../../interfaces/appointment";

export const appointmentFeatureKey = 'appointment';

export interface State {
  iri: string | null;
  appointment: Appointment | null;
  loading: boolean;
}

export const initialState: State = {
  iri: localStorage.getItem('appointment'),
  appointment: null,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(AppointmentActions.setAppointmentIri, (state, action) => {
    return {...state, iri: action.iri}
  }),
  on(AppointmentActions.loadAppointment, state => {
    return {...state, loading: true}
  }),
  on(AppointmentActions.loadAppointmentSuccess, (state, action) => {
    return {...state, loading: false, appointment: action.data}
  }),
  on(AppointmentActions.loadAppointmentFailure, (state, action) => {
    return {...state, loading: false}
  }),
);
