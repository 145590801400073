import {Directive, HostBinding} from '@angular/core';
import {LoadScreenService} from "../services/load-screen.service";
import {distinctUntilChanged} from "rxjs/operators";

@Directive({
  standalone: true,
  selector: '[appLoadScreen]'
})
export class LoadScreenDirective {

  @HostBinding('class.show') isShown = true;
  private defaultTimeOut: number = 250;

  constructor(
    private loadScreenService: LoadScreenService
  ) {
    this.loadScreenService.status$
      .pipe(distinctUntilChanged())
      .subscribe(status => {

        if(this.isShown === status) {
          return;
        }

        setTimeout(
          () => this.isShown = status,
          !status ? this.defaultTimeOut : 0);
      });
  }
}
