import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from "@angular/common/http";
import {Appointment} from "../../../interfaces/appointment";

export const setAppointmentIri = createAction(
  '[Appointment] Set Appointment IRI',
  props<{ iri: string | null }>()
)

export const loadAppointment = createAction(
  '[Appointment] Load Appointment'
);

export const loadAppointmentSuccess = createAction(
  '[Appointment] Load Appointment Success',
  props<{ data: Appointment }>()
);

export const loadAppointmentFailure = createAction(
  '[Appointment] Load Appointment Failure',
  props<{ error: HttpErrorResponse }>()
);
