import {Action, createReducer, on} from '@ngrx/store';
import * as UserActions from './user.actions';
import {User} from "../../../interfaces/user";

export const userFeatureKey = 'user';

export interface State {
  iri: string | null;
  user: User | null;
  loading: boolean;
}

export const initialState: State = {
  iri: localStorage.getItem('ident'),
  user: null,
  loading: false
};

export const reducer = createReducer(
  initialState,

  on(UserActions.setUsersIri, (state, action) => {
    return {...state, iri: action.iri}
  }),
  on(UserActions.loadUser, state => {
    return {...state, loading: true}
  }),
  on(UserActions.loadUserSuccess, (state, action) => {
    return {...state, user: action.data, loading: false}
  }),
  on(UserActions.loadUserFailure, (state, action) => {
    return {...state, loading: false}
  }),

  on(UserActions.updateUserSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      user: action.data
    }
  }),

  /**
   * Logout
   */

  on(UserActions.logoutUser, (state, action) => {
    return {
      ...state,
      loading: false,
      user: null,
      iri: null
    }
  })
);
