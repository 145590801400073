import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from "./authentication.guard";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'members'},
  {
    path: 'login',
    loadComponent: () => import('./user/login-form/login-form.component').then(m => m.LoginFormComponent)
  },
  {
    path: 'logout',
    loadComponent: () => import('./user/logout/logout.component').then(m => m.LogoutComponent)
  },
  {
    /**
     * Link sent by an email to the customer
     */
    // TODO: Change Route
    path: 'user/login/execute',
    loadComponent: () => import('./user/login-link/login-link.component').then(m => m.LoginLinkComponent)
  },
  {
    /**
     * Link created by the Database-App
     */
    path: 'checkin/:customerUuid/:appointmentId',
    loadComponent: () => import('./remote-checkin/remote-checkin.component').then(m => m.RemoteCheckinComponent)
  },
  {
    /**
     * Glamourmed Remote Login
     */
    path: 'glamourmed/:email/:appid',
    loadComponent: () => import('./glamourmed/checkin/checkin.component').then(m => m.CheckinComponent)
  },
  {
    path: 'members',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
  },
  {path: '**', redirectTo: 'members'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class AppRoutingModule {
}
