import {Injectable} from "@angular/core";
import {Observable, take} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {IToken} from "../interfaces/token.model";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {loadUser, logoutUser, setUsersIri} from "../members/user/store/user.actions";
import {selectUserIri} from "../members/user/store/user.selectors";

export interface LoginLinkParams {
  expires: string;
  hash: string;
  user: string;
  app: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {
  public ident$ = this.store.select(selectUserIri);

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly store: Store
  ) {

    this.ident$
      .pipe(
        distinctUntilChanged(),
        filter(iri => iri !== localStorage.getItem('ident')),
        tap(iri => iri ? localStorage.setItem('ident', iri) : localStorage.removeItem('ident'))
      )
      .subscribe()

    this.ident$
      .pipe(distinctUntilChanged())
      .pipe(
        filter(ident => !!ident),
        tap(ident => localStorage.setItem('ident', ident!)),
        tap(ident => this.store.dispatch(loadUser({iri: ident!})))
      )
      .subscribe();
  }

  /**
   * Logout User
   */

  logoutUser(redirect: boolean = true, data: any = {}) {
    this.http.get('/api/logout').subscribe();
    localStorage.clear();

    // Reset Token and Ident
    this.store.dispatch(logoutUser());

    if (redirect) {
      this.router.navigate(['/login', data]);
    }
  }

  /**
   * GM Login User
   *
   * @param email Appointment Email
   * @param appid Appointment initial id
   */

  public gmLoginUser(email: string, appid: number) {
    const data = {
      email: email,
      appid: appid
    }

    return this.http
      .post<IToken>(`/api/glamourmed/login`, data, {observe: "response"})
      .pipe(
        tap(r => this.store.dispatch(setUsersIri({iri: r.headers.get('location')})))
      )
  }

  /**
   * Remote Checkin
   *
   * @param customerId Customer UUID
   * @param appointmentId Appointment initial id
   */
  public remoteLogin(customerId: string, appointmentId: number) {

    const data = {
      customerId,
      appointmentId
    }

    return this.http
      .post(`/api/remote-login`, data, {observe: "response"})
      .pipe(
        tap(r => this.store.dispatch(setUsersIri({iri: r.headers.get('location')})))
      );
  }

  /**
   * Execute Login Link
   */

  public executeLoginLink(params: LoginLinkParams) {
    const queryParams = (new HttpParams())
      .appendAll({...params});

    return this.http.post('/api/login_check', {},
      {
        params: queryParams,
        observe: "response"
      }
    )
      .pipe(
        tap(r => this.store.dispatch(setUsersIri({iri: r.headers.get('location')}))),
      );
  }

  /**
   * Return true when user is logged in
   */
  public isLoggedIn(): Observable<boolean> {
    return this.ident$.pipe(
      take(1),
      map(iri => !!iri)
    )
  }
}
