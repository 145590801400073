import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromSurvey from './survey.reducer';

export const selectSurveyState = createFeatureSelector<fromSurvey.State>(
  fromSurvey.surveyFeatureKey
);

export const selectSurveyData = createSelector(
  selectSurveyState,
  fromSurvey.selectAll
)

export const selectSurveyItemRaw = (key: string) => createSelector(
  selectSurveyState,
  state => state.entities[key]?.raw
)

export const selectSurveyUploaded = createSelector(
  selectSurveyState,
  state => state.uploaded
)
