import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {switchMap, take} from "rxjs";
import {filter, map} from "rxjs/operators";
import {UserFactory} from "../factories/user-factory";
import {UserRaw} from "../interfaces/user";
import {ISurvey} from "../interfaces/survey.model";
import {Store} from "@ngrx/store";
import {selectUser, selectUserIri} from "../members/user/store/user.selectors";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly store: Store
  ) {
  }

  updateUser(iri: string, data: any) {

    return this.store
      .select(selectUserIri)
      .pipe(
        filter(iri => !!iri),
        map(iri => iri!),
        switchMap(
          iri => this.http.patch<UserRaw>(
            iri,
            data,
            {
              headers: new HttpHeaders({'Content-Type': 'application/merge-patch+json'})
            }
          ).pipe(
            map(user => UserFactory.fromRaw(user))
          )
        ),
      )
  }

  setSurvey(survey: ISurvey) {

    return this.store
      .select(selectUser)
      .pipe(
        take(1),
        filter(user => !!user),
        switchMap(user => this.http
          .patch<UserRaw>(user!['@id'], {
              checkIn: {
                survey: Object.assign(
                  {
                    ...(user!.checkIn?.survey ?? {})
                  },
                  {[survey.key]: survey.raw}
                )
              }
            },
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/merge-patch+json',
              })
            })
          .pipe(
            map(user => UserFactory.fromRaw(user))
          ))
      )
  }

  public getUser(iri: string) {
    return this.http.get<UserRaw>(iri)
      .pipe(
        map(u => UserFactory.fromRaw(u)),
      )
  }

  /**
   * Request Login Link (will be sent by E-Mail)
   */
  public requestLoginLink(email: string, locale: string) {
    return this.http.post("/api/customer_login", {email, locale});
  }
}
