import {createAction, props} from '@ngrx/store';
import {ISurvey} from "../../../interfaces/survey.model";
import {HttpErrorResponse} from "@angular/common/http";

export const setSurveyItem = createAction(
  '[Survey] Set Survey Item',
  props<{ item: ISurvey }>()
)

export const setSurveyItemSuccess = createAction(
  '[Survey] Set Survey Item Success'
)

export const setSurveyAll = createAction(
  '[Survey] Set Survey All Data',
  props<{ data: ISurvey[] }>()
)

export const setSurveyUserData = createAction(
  '[Survey] Set Survey User Data',
  props<{ data: ISurvey[] }>()
)

/**
 * Upload Survey
 */
export const uploadSurvey = createAction(
  '[Survey API] Upload Survey',
)

export const uploadSurveySuccess = createAction(
  '[Survey API] Upload Survey Success',
)

export const uploadSurveyFailure = createAction(
  '[Survey API] Upload Survey Failure',
  props<{ error: HttpErrorResponse }>()
)
