import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public locUrl$ = new BehaviorSubject<string>('https://www.lloydmed.de/');

  public setLocationUrl(url: string) {
    if (!url) {
      return;
    }

    this.locUrl$.next(url);
  }
}
