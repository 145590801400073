import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAppointment from './appointment.reducer';

export const selectAppointmentState = createFeatureSelector<fromAppointment.State>(
  fromAppointment.appointmentFeatureKey
);

export const selectAppointment = createSelector(
  selectAppointmentState,
  state => state.appointment
)
