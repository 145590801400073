import {DEFAULT_CURRENCY_CODE, NgModule, isDevMode, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorInterceptor} from "./error.interceptor";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {CssLoaderComponent} from "./components/css-loader/css-loader.component";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import * as fromUser from "./members/user/store/user.reducer";
import * as fromAppointment from "./members/appointment/store/appointment.reducer";
import {UserEffects} from "./members/user/store/user.effects";
import {AppointmentEffects} from "./members/appointment/store/appointment.effects";

import * as Sentry from "@sentry/angular"
import {Router} from "@angular/router";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CssLoaderComponent,
    StoreModule.forRoot({
      [fromUser.userFeatureKey]: fromUser.reducer,
      [fromAppointment.appointmentFeatureKey]: fromAppointment.reducer
    }, {}),
    EffectsModule.forRoot([UserEffects, AppointmentEffects]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: 'dd.MM.Y'}},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
