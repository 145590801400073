import {Action, createReducer, on} from '@ngrx/store';
import * as SurveyActions from './survey.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ISurvey} from "../../../interfaces/survey.model";

export const surveyFeatureKey = 'survey';

export interface State extends EntityState<ISurvey> {
  uploaded: boolean
}

export const adapter: EntityAdapter<ISurvey> = createEntityAdapter<ISurvey>({
  selectId: survey => survey.key
})

export const initialState: State = adapter.getInitialState({
  uploaded: false
});

export const reducer = createReducer(
  initialState,

  on(SurveyActions.setSurveyItem, (state, action) => adapter.upsertOne(action.item, {...state})),
  on(SurveyActions.setSurveyAll, (state, action) => adapter.setAll(action.data, {...state})),
  on(SurveyActions.setSurveyUserData, (state, action) => adapter.upsertMany(action.data, {...state})),
  on(SurveyActions.uploadSurveySuccess, (state, action) => {
    return {
      ...state,
      uploaded: true
    }
  }),

  /**
   * Upload Survey
   */

  on(SurveyActions.uploadSurvey, (state, action) => {
    return {...state}
  })
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors()
