import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as AppointmentActions from './appointment.actions';
import {of, switchMap, take} from "rxjs";
import {AppointmentService} from "../../../services/appointment.service";
import {catchError, filter, map} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {selectAppointmentState} from "./appointment.selectors";


@Injectable()
export class AppointmentEffects {

  appIri$ = this.store.select(selectAppointmentState).pipe(filter(state => !!state.iri), map(state => state.iri!))

  loadAppointments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppointmentActions.loadAppointment),
      switchMap(() => this.appIri$
        .pipe(
          take(1),
          switchMap(iri => this.appointmentService.getAppointment(iri).pipe(
            map(app => AppointmentActions.loadAppointmentSuccess({data: app})),
            catchError(err => of(AppointmentActions.loadAppointmentFailure({error: err}))))
          )
        ))
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly appointmentService: AppointmentService
  ) {
  }
}
